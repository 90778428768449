/* eslint-disable no-undef */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { getJobs, searchJobs } from "../../services/jobs";
import com_logo_1 from "../../img/com_logo_1.jpg";

const Listing = (props) => {
  // const navigate = useNavigate();
  const [featured, setFeatured] = useState("active");
  const [fullTime, setFullTime] = useState("inActive");
  const [partTime, setPartTime] = useState("inActive");
  const [jobLists, setjobLists] = useState([]);
  const [getError, setErrors] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [inputs, setInputs] = useState({});
  const [loading, setLoader] = useState(true);

  // const handleFeatured = () => {
  //   setFeatured("active");
  //   setFullTime("inActive");
  //   setPartTime("inActive");
  // };

  // const handleFullTime = () => {
  //   setFullTime("active");
  //   setFeatured("inActive");
  //   setPartTime("inActive");
  // };

  // const handlePartTime = () => {
  //   setPartTime("active");
  //   setFullTime("inActive");
  //   setFeatured("inActive");
  // };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getJobs().then((res) => res.json());
        if (response) {
          setLoader(false);
          setjobLists(response);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleSearchChange = async (e) => {
    
    e.preventDefault();
    if (
      inputs === undefined ||
      (inputs.job_type === "undefined" && inputs.category === "undefined")
    ) {
      alert("Please Select Category & Job Type");
      return;
    } else if (
      inputs.category === undefined ||
      inputs.category === "undefined"
    ) {
      alert("Please Select Category");
      return;
    } else if (
      inputs.job_type === undefined ||
      inputs.job_type === "undefined"
    ) {
      alert("Please Select Job Type");
      return;
    } else if (inputs.job_type && inputs.category) {
      setSearchTerm(inputs);
      setLoader(true);
      const response = await searchJobs(inputs).then((res) => res.json());
      if (response) {
        setLoader(false);
        setjobLists(response);
      } else {
        setjobLists([]);
      }
      console.log(response);
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  return (
    <>
      {/* Jobs Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div
            className="container-fluid bg-primary mb-5 wow fadeIn"
            data-wow-delay="0.1s"
            style={{ padding: "35px" }}
          >
            <div className="container">
              <form onSubmit={handleSearchChange}>
                <div className="row g-2">
                  <div className="col-md-10">
                    <div className="row g-2">
                      {/* <div className="col-md-4">
                        <input
                          type="text"
                          className="form-control border-0"
                          placeholder="Keyword"
                          name="title"
                          value={inputs.title || ""}
                          onChange={handleChange}
                        />
                      </div> */}
                      <div className="col-md-6">
                        <select
                          defaultValue={inputs.category || ""}
                          className="form-select border-0"
                          name="category"
                          onChange={handleChange}
                        >
                          <option value="undefined">Select Job Category</option>
                          <option value="Full Stack Developer">
                            Full Stack Developer
                          </option>
                          <option value="Front End Developer">
                            Front End Developer
                          </option>
                          <option value="Engineering Manager">
                            Engineering Manager
                          </option>
                          <option value="Mobile App Developer">
                            Mobile App Developer
                          </option>
                          <option value="Technical Lead">Technical Lead</option>
                          <option value="DevOps Engineer">
                            DevOps Engineer
                          </option>
                          <option value="Product Manager">
                            Product Manager
                          </option>
                        </select>
                      </div>
                      <div className="col-md-6">
                        <select
                          defaultValue={inputs.job_type || ""}
                          className="form-select border-0"
                          name="job_type"
                          onChange={handleChange}
                        >
                          <option value="undefined">Select Job Type</option>
                          <option value="Full Time">Full Time</option>
                          <option value="Part Time">Part Time</option>
                          <option value="Remote">Remote</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <button className="btn btn-dark border-0 w-100">
                      Search
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <h1 className="text-center mb-5 wow fadeInUp" data-wow-delay="0.1s">
            Discover the {jobLists.length} Most Popular Jobs in Today's Market
          </h1>

          <div
            className="tab-class text-center wow fadeInUp"
            data-wow-delay="0.3s"
          >
            {/* <ul className="nav nav-pills d-inline-flex justify-content-center border-bottom mb-5">
              <li className="nav-item">
                <a
                  className={`d-flex align-items-center text-start mx-3 ms-0 pb-3 ${featured}`}
                  // data-bs-toggle="pill"
                  href="#tab-1"
                  onClick={handleFeatured}
                >
                  <h6 className="mt-n1 mb-0">Featured</h6>
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`d-flex align-items-center text-start mx-3 pb-3 ${fullTime}`}
                  // data-bs-toggle="pill"
                  href="#tab-2"
                  onClick={handleFullTime}
                >
                  <h6 className="mt-n1 mb-0">Full Time</h6>
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`d-flex align-items-center text-start mx-3 me-0 pb-3 ${partTime}`}
                  // data-bs-toggle="pill"
                  href="#tab-3"
                  onClick={handlePartTime}
                >
                  <h6 className="mt-n1 mb-0">Part Time</h6>
                </a>
              </li>
            </ul> */}
            <div className="tab-content">
              {loading && (
                <div
                  id="spinner"
                  className="show position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
                >
                  <div
                    className="spinner-border text-primary"
                    style={{ width: "3rem", height: "3rem" }}
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )}

              <div id="tab-1" className={`tab-pane fade show p-0 ${featured}`}>
                {jobLists.map((jList) => (
                  <a href={`/job-details/${jList.id}`}>
                    <div key={jList.id} className="job-item p-4 mb-4">
                      <div className="row g-4">
                        <div className="col-sm-12 col-md-8 d-flex align-items-center">
                          <img
                            className="flex-shrink-0 img-fluid border rounded"
                            src={com_logo_1}
                            alt=""
                            style={{ width: "80px", height: "80px" }}
                          />
                          <div className="text-start ps-4">
                            <h5 className="mb-3">{jList.title}</h5>
                            <span className="text-truncate me-3">
                              <i className="fa fa-map-marker-alt text-primary me-2" />
                              {/* New York, USA */}
                              {jList.location}
                            </span>
                            <span className="text-truncate me-3">
                              <i className="far fa-clock text-primary me-2" />
                              {jList.job_type}
                            </span>
                            <span className="text-truncate me-0">
                              <i className="far fa-money-bill-alt text-primary me-2" />
                              {/* $123 - $456 */}
                              {jList.salary}
                            </span>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-4 d-flex flex-column align-items-start align-items-md-end justify-content-center">
                          <div className="d-flex mb-3">
                            {/* <a className="btn btn-light btn-square me-3" href="/">
                            <i className="far fa-heart text-primary" />
                          </a> */}
                            {/* <a className="btn btn-primary" onClick={()=>applyJobNow('test string')}>
                            Apply Now 1
                          </a> */}
                            <Link
                              className="btn btn-primary"
                              to={`/job-details/${jList.id}`}
                            >
                              {"Apply Now"}
                            </Link>
                          </div>
                          {/* <small className="text-truncate">
                          <i className="far fa-calendar-alt text-primary me-2" />
                          Date Line: 01 Jan, 2045
                        </small> */}
                        </div>
                      </div>
                    </div>
                  </a>
                ))}

                {/* <a className="btn btn-primary py-3 px-5" href="/">
                  Browse More Jobs
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Jobs End */}
    </>
  );
};

export default Listing;
