import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import com_logo_1 from "../../img/com_logo_1.jpg";
import Header from "./header";
import Footer from "./footer";
import { getJob } from "../../services/jobs";

const JobDetails = () => {
  const { id } = useParams();
  const [job, setJob] = useState([]);
  const [inputs, setInputs] = useState({});
  
  useEffect(() => {
    const fetchJobData = async () => {
      try {
        const response = await getJob(id).then((res) => res.json());
        if(response){
          setJob(response);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchJobData();
  }, [id]);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    alert('We are not accepting any new profile')
  };

  // const handleUpload = () => {
  //   console.log(activityImage, "getting");

  //   // const { activityImage } = activityImage1;
  //   const uploadImage = storage
  //     .ref(`activityImages/${activityImage.name}`)
  //     .put(activityImage);
  //   uploadImage.on(
  //     "state_changed",
  //     snapshot => {
  //       //progress Function...............
  //       // const progress = Math.round(
  //       //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100
  //       // );
  //       // this.setState({ progress });
  //     },
  //     error => {
  //       //error Function
  //       console.log(error, "This Is error");
  //     },
  //     () => {
  //       //competed Function
  //       storage
  //         .ref("activityImages")
  //         .child(activityImage.name)
  //         .getDownloadURL()
  //         .then(activityImageURL => {
  //           setActivityImageURL(activityImageURL);
  //           console.log(activityImageURL, "getting url");
  //         });
  //     }
  //   );
  // };

  return (
    <div>
      <meta charSet="utf-8" />
      <title>{job.title}</title>
      <meta content="width=device-width, initial-scale=1.0" name="viewport" />
      <meta content name="keywords" />
      <meta content name="description" />

      <div className="container-xxl bg-white p-0">
        {/* Spinner Start */}
        {/* <div id="spinner" className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <div className="spinner-border text-primary" style={{width: '3rem', height: '3rem'}} role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div> */}
        {/* Spinner End */}
        {/* Navbar Start */}
        <Header path="job_details"></Header>
        {/* Navbar End */}
        {/* Header End */}
        <div className="container-xxl py-5 bg-dark page-header mb-5">
          <div className="container my-5 pt-5 pb-4">
            <h1 className="display-3 text-white mb-3 animated slideInDown">
              {job.title}
            </h1>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb text-uppercase">
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                {/* <li className="breadcrumb-item">
                  <a href="#">Pages</a>
                </li> */}
                <li
                  className="breadcrumb-item text-white active"
                  aria-current="page"
                >
                  Job Details
                </li>
              </ol>
            </nav>
          </div>
        </div>
        {/* Header End */}
        {/* Job Detail Start */}
        <div className="container-xxl py-5 wow fadeInUp" data-wow-delay="0.1s">
          <div className="container">
            <div className="row gy-5 gx-4">
              <div className="col-lg-8">
                <div className="d-flex align-items-center mb-5">
                  <img
                    className="flex-shrink-0 img-fluid border rounded"
                    src={com_logo_1}
                    alt=""
                    style={{ width: "80px", height: "80px" }}
                  />
                  <div className="text-start ps-4">
                    <h3 className="mb-3">{job.title}</h3>
                    <span className="text-truncate me-3">
                      <i className="fa fa-map-marker-alt text-primary me-2" />
                      {job.location}
                    </span>
                    <span className="text-truncate me-3">
                      <i className="far fa-clock text-primary me-2" />
                      {job.job_type}
                    </span>
                    <span className="text-truncate me-0">
                      <i className="far fa-money-bill-alt text-primary me-2" />
                      {job.salary}
                    </span>
                  </div>
                </div>
                <div className="mb-5">
                  <h4 className="mb-3">Job description</h4>
                  <div dangerouslySetInnerHTML={{__html: job.description }} />
                  <h4 className="mb-3">Responsibility</h4>
                  <ul className="list-unstyled">
                    <div dangerouslySetInnerHTML={{__html: job.responsibility }} />
                  </ul>
                  <h4 className="mb-3">Qualifications</h4>
                  <div dangerouslySetInnerHTML={{__html: job.qualifications }} />
                </div>
                <div className>
                  <h4 className="mb-4">Apply For The Job</h4>
                  <form onSubmit={handleSubmit}>
                    <div className="row g-3">
                      <div className="col-12 col-sm-6">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Your Name"
                          value={inputs.name || ""}
                          name="name"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-12 col-sm-6">
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Your Email"
                          value={inputs.email || ""}
                          name="email"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-12 col-sm-6">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Portfolio Website"
                          value={inputs.portfolio_website || ""}
                          name="portfolio_website"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-12 col-sm-6">
                        <input type="file" className="form-control bg-white" value={inputs.resume || ""}
                          name="resume"
                          onChange={handleChange} />
                      </div>
                      <div className="col-12">
                        <textarea
                          className="form-control"
                          rows={5}
                          placeholder="Coverletter"
                          value={inputs.coverletter || ""}
                          name="coverletter"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-12">
                        <button className="btn btn-primary w-100" type="submit">
                          Apply Now
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="bg-light rounded p-5 mb-4 wow slideInUp"
                  data-wow-delay="0.1s"
                >
                  <h4 className="mb-4">Job Summery</h4>
                  {/* <p>
                    <i className="fa fa-angle-right text-primary me-2"></i>
                    Published On: 01 Jan, 2045
                  </p>
                  <p>
                    <i className="fa fa-angle-right text-primary me-2"></i>
                    Vacancy: 123 Position
                  </p> */}
                  <p>
                    <i className="fa fa-angle-right text-primary me-2"></i>
                    Job Nature:  {job.job_type}
                  </p>
                  <p>
                    <i className="fa fa-angle-right text-primary me-2"></i>
                    Salary:  {job.salary}
                  </p>
                  <p>
                    <i className="fa fa-angle-right text-primary me-2"></i>
                    Location: {job.location}
                  </p>
                  {/* <p className="m-0">
                    <i className="fa fa-angle-right text-primary me-2"></i>
                    Date Line: 01 Jan, 2045
                  </p> */}
                </div>
                <div
                  className="bg-light rounded p-5 wow slideInUp"
                  data-wow-delay="0.1s"
                >
                  <h4 className="mb-4">Company Detail</h4>
                  <p className="m-0">
                  Our Company is a leading IT industry/sectors.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Job Detail End */}
        {/* Footer Start */}
        <Footer></Footer>
        {/* Footer End */}
        {/* Back to Top */}
        <a
          href="#"
          className="btn btn-lg btn-primary btn-lg-square back-to-top"
        >
          <i className="bi bi-arrow-up" />
        </a>
      </div>
      {/* JavaScript Libraries */}
      {/* Job Portal Website Javascript */}
    </div>
  );
};
export default JobDetails
