/* eslint-disable jsx-a11y/anchor-is-valid */

import Header from './header';
import Footer from './footer';
import Listing from './listing';

const JobList = () => (
  <div>
    {/* Job Portal Website Stylesheet */}
    <div className="container-xxl bg-white p-0">
      {/* Spinner Start */}
      {/* <div id="spinner" className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
        <div className="spinner-border text-primary" style={{width: '3rem', height: '3rem'}} role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div> */}
      {/* Spinner End */}
      {/* Navbar Start */}
        <Header path='job_list'></Header>
      {/* Navbar End */}
      {/* Header End */}
      <div className="container-xxl py-5 bg-dark page-header mb-5">
        <div className="container my-5 pt-5 pb-4">
          <h1 className="display-3 text-white mb-3 animated slideInDown">Trending Jobs</h1>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb text-uppercase">
              <li className="breadcrumb-item"><a href="/">Home</a></li>
              {/* <li className="breadcrumb-item"><a href="#">Pages</a></li> */}
              <li className="breadcrumb-item text-white active" aria-current="page">Jobs</li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Header End */}
      {/* Jobs Start */}
        <Listing></Listing>
      {/* Jobs End */}
      {/* Footer Start */}
        <Footer></Footer>
      {/* Footer End */}
      {/* Back to Top */}
      <a href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top"><i className="bi bi-arrow-up" /></a>
    </div>
    {/* JavaScript Libraries */}
    {/* Job Portal Website Javascript */}
  </div>
);

export default JobList;