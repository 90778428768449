/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import about_1 from "../../img/about_1.jpg";
import about_2 from "../../img/about_2.jpg";
import about_3 from "../../img/about_3.jpg";
import about_4 from "../../img/about_4.jpg";
import Header from "./header";
import Footer from "./footer";
const AboutUs = () => (
  <div>
    <div className="container-xxl bg-white p-0">
      {/* Navbar Start */}
      <Header path="about_us"></Header>
      {/* Navbar End */}
      {/* Header End */}
      <div className="container-xxl py-5 bg-dark page-header mb-5">
        <div className="container my-5 pt-5 pb-4">
          <h1 className="display-3 text-white mb-3 animated slideInDown">
            About Us
          </h1>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb text-uppercase">
              <li className="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              <li
                className="breadcrumb-item text-white active"
                aria-current="page"
              >
                About
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Header End */}
      {/* About Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
              <div className="row g-0 about-bg rounded overflow-hidden">
                <div className="col-6 text-start">
                  <img className="img-fluid w-100" src={about_1} />
                </div>
                <div className="col-6 text-start">
                  <img
                    className="img-fluid"
                    src={about_2}
                    style={{ width: "85%", marginTop: "15%" }}
                  />
                </div>
                <div className="col-6 text-end">
                  <img
                    className="img-fluid"
                    src={about_3}
                    style={{ width: "85%" }}
                  />
                </div>
                <div className="col-6 text-end">
                  <img className="img-fluid w-100" src={about_4} />
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
              <h1 className="mb-4">
                Your Path to Career Success and Talent Discovery
              </h1>
              <p className="mb-4">
                Welcome to Our platform, your premier destination for navigating
                the world of employment opportunities. At Our platform, we
                understand that finding the perfect job can be a daunting task,
                and that's why we're here to simplify the process for you. With
                a user-friendly interface and powerful search tools, we've
                curated an extensive database of job listings from a wide range
                of industries and sectors.
                {/* Whether you're a recent graduate,
                seasoned professional, or looking to make a career change, we've
                got you covered. But we're more than just a job board. We're
                your partners in career success. Our platform offers
                personalized job recommendations, resume optimization services,
                interview preparation resources, and expert career guidance to
                help you land your dream job. For employers, we provide
                innovative recruiting solutions to connect you with top talent
                that aligns with your company's values and objectives. From
                employer branding initiatives to data-driven insights, we're
                committed to helping you build a high-performing team. At Our
                platform, we believe that everyone deserves to find meaningful
                work that brings fulfillment and purpose to their lives. Join us
                today and let's embark on this journey together towards a
                brighter, more prosperous future. */}
              </p>
              <h2 className="mb-4">Our Mission</h2>
              <p>
                <i className="fa fa-check text-primary me-3" />
                <b>Empowering Career Growth: </b>
                We are dedicated to empowering individuals to achieve their
                career aspirations by providing them with the tools and
                resources they need to succeed.
              </p>
              <p>
                <i className="fa fa-check text-primary me-3" />
                <b>Streamlined Job Search: </b>
                Our platform offers a streamlined job search experience,
                allowing users to easily browse through thousands of job
                listings tailored to their skills, experience, and preferences.
              </p>
              <p>
                <i className="fa fa-check text-primary me-3" />
                <b>Personalized Support: </b>
                We believe in the power of personalized support. From resume
                optimization to interview preparation, our team is committed to
                providing personalized guidance to help you stand out in the
                competitive job market.
              </p>
              <p>
                <i className="fa fa-check text-primary me-3" />
                <b>Connecting Talent with Opportunity: </b>
                We're not just a job board; we're matchmakers. Our advanced
                algorithms connect talented individuals with exciting job
                opportunities that align with their career goals and
                aspirations.
              </p>
            </div>
            <div className="col-lg-12 wow fadeIn" data-wow-delay="0.5s">
              <h2 className="mb-4">For Job Seekers</h2>
              <p>
                <i className="fa fa-check text-primary me-3" />
                <b>Extensive Job Listings: </b>
                Access to an extensive database of job listings from diverse
                industries and sectors, ensuring you find the perfect fit for
                your skills and experience.
              </p>
              <p>
                <i className="fa fa-check text-primary me-3" />
                <b>Community Support: </b>
                Join a vibrant community of job seekers where you can network,
                share experiences, and support each other in your career
                journeys.
              </p>
              <p>
                <i className="fa fa-check text-primary me-3" />
                <b>Career Guidance: </b>
                Expert advice and resources to help you navigate your career
                path, from resume writing tips to interview coaching.
              </p>
              <h2 className="mb-4">For Employers</h2>

              <p>
                <i className="fa fa-check text-primary me-3" />
                <b>Top Talent Acquisition: </b>
                Gain access to a pool of top talent and streamline your hiring
                process with our innovative recruiting solutions.
              </p>

              <p>
                <i className="fa fa-check text-primary me-3" />
                <b>Employer Branding: </b>
                Showcase your company culture and values to attract the best
                candidates and stand out as an employer of choice.
              </p>

              <p>
                <i className="fa fa-check text-primary me-3" />
                <b>Data-Driven Insights: </b>
                Utilize data-driven insights to make informed hiring decisions
                and optimize your recruitment strategy for success.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* About End */}
      {/* Footer Start */}
      <Footer></Footer>
      {/* Footer End */}
      {/* Back to Top */}
      <a href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top">
        <i className="bi bi-arrow-up" />
      </a>
    </div>
    {/* JavaScript Libraries */}
    {/* Job Portal Website Javascript */}
  </div>
);

export default AboutUs;
