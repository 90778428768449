/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import testimonial_1 from "../../img/testimonial_1.jpg";
import testimonial_2 from "../../img/testimonial_2.jpg";
import testimonial_3 from "../../img/testimonial_3.jpg";
import testimonial_4 from "../../img/testimonial_4.jpg";
// eslint-disable-next-line import/no-anonymous-default-export
export default () => (
  <>
    <div className="container-xxl py-5 wow fadeInUp" data-wow-delay="0.1s">
      <div className="container">
        <h1 className="text-center mb-5">Our Clients Say!!!</h1>
        <Carousel autoPlay showArrows={true} showThumbs={false}>
          <div className="owl-carousel testimonial-carousel">
            <div className="testimonial-item bg-light rounded p-4">
              <i className="fa fa-quote-left fa-2x text-primary mb-3" />
              <p>
                Finding the perfect job was like searching for a needle in a
                haystack until we discovered this job portal. The personalized
                job matching feature helped us find the ideal candidates for our
                company's unique requirements swiftly. The platform's
                user-friendly interface and expert guidance have truly
                simplified our hiring process. We couldn't be happier with the
                exceptional talent we've found through this portal. Highly
                recommended!
              </p>
              <div className="d-flex align-items-center">
                <img
                  className="img-fluid flex-shrink-0 rounded"
                  src={testimonial_1}
                  style={{ width: "50px", height: "50px" }}
                />
                <div className="ps-3">
                  <h5 className="mb-1">Akanksha Puri</h5>
                  <small> Sr. Mobile Developer</small>
                </div>
              </div>
            </div>
          </div>
          <div className="owl-carousel testimonial-carousel">
            <div className="testimonial-item bg-light rounded p-4">
              <i className="fa fa-quote-left fa-2x text-primary mb-3" />
              <p>
                Finding the right talent for our company has always been a
                challenge, but ever since we started using this job portal, the
                process has been a breeze! Their advanced matching algorithms
                and extensive database of candidates have helped us discover
                exceptional talent that perfectly fits our company culture and
                requirements. The personalized support and guidance from their
                team have been invaluable, making the hiring process efficient
                and enjoyable. We couldn't be happier with the results and
                highly recommend this portal to any organization looking to
                streamline their recruitment efforts.
              </p>
              <div className="d-flex align-items-center">
                <img
                  className="img-fluid flex-shrink-0 rounded"
                  src={testimonial_2}
                  style={{ width: "50px", height: "50px" }}
                />
                <div className="ps-3">
                  <h5 className="mb-1">Ajay Sharma</h5>
                  <small> Sr. Software Developer</small>
                </div>
              </div>
            </div>
          </div>
        </Carousel>
      </div>
    </div>
  </>
);
