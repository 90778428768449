/* eslint-disable jsx-a11y/anchor-is-valid */
const Header = (props) => {
  const appName = document.location.origin.includes('maltimediagroup') ? 'Maltimedia Group Jobs' : "Ryientsoft Jobs"

  return (
    <>
      <meta charSet="utf-8" />
      <title>Job Portal Website</title>
      <meta content="width=device-width, initial-scale=1.0" name="viewport" />
      <meta name="keywords" content="remote jobs, Remote job openings, remote career opportunities, Work-from-home jobs, Remote positions, telecommute jobs, Remote work opportunities, virtual job listings, emote employment, remote job vacancies"/>
      <meta name="description" content="Explore remote job opportunities at Ryientsoft. Find work-from-home positions and join our team of talented professionals."></meta>

      <nav className="navbar navbar-expand-lg bg-white navbar-light shadow sticky-top p-0">
        <a
          href="/"
          className="navbar-brand d-flex align-items-center text-center py-0 px-4 px-lg-5"
        >
          <h1 className="m-0 text-primary">{appName}</h1>
        </a>
        <button
          type="button"
          className="navbar-toggler me-4"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav ms-auto p-4 p-lg-0">
            <a
              href="/"
              className={
                props.path === "/"
                  ? "nav-item nav-link active"
                  : "nav-item nav-link"
              }
            >
              Home
            </a>
            <a
              href="/about"
              className={
                props.path === "about_us"
                  ? "nav-item nav-link active"
                  : "nav-item nav-link"
              }
            >
              About Us
            </a>
            <a
              href="/jobs"
              className={
                props.path === "jobs"
                  ? "nav-item nav-link active"
                  : "nav-item nav-link"
              }
            >
              Jobs
            </a>

            {/* <a
              href="/create-jobs"
              className={
                props.path === "create-jobs"
                  ? "nav-item nav-link active"
                  : "nav-item nav-link"
              }
            >
              Create Jobs
            </a> */}

            {/* <div className="nav-item dropdown">
              <a
                href="#"
                className={
                  props.path === "job_list" || props.path === "job_details"
                    ? "nav-link dropdown-toggle active"
                    : "nav-link dropdown-toggle"
                }
                data-bs-toggle="dropdown"
              >
                Jobs
              </a>
              <div className="dropdown-menu rounded-0 m-0">
                <a
                  href="/jobs"
                  className={
                    props.path === "job_list"
                      ? "dropdown-item active"
                      : "dropdown-item"
                  }
                >
                  Jobs
                </a>
                <a
                  href="/job-details"
                  className={
                    props.path === "job_details"
                      ? "dropdown-item active"
                      : "dropdown-item"
                  }
                >
                  Job Detail
                </a>
              </div>
            </div> */}
          {/* <div className="nav-item dropdown">
            <a
              href="#"
              className="nav-link dropdown-toggle"
              data-bs-toggle="dropdown"
            >
              Pages
            </a>
            <div className="dropdown-menu rounded-0 m-0">
              <a href="/category" className="dropdown-item">
                Job Category
              </a>
              <a href="testimonial.html" className="dropdown-item">
                Testimonial
              </a>
              <a href="404.html" className="dropdown-item">
                404
              </a>
            </div>
          </div> */}
            {/* <a
              href="/category"
              className={
                props.path === "category"
                  ? "nav-item nav-link active"
                  : "nav-item nav-link"
              }
            >
              Job Category
            </a> */}
            <a
              href="/contact"
              className={
                props.path === "contact"
                  ? "nav-item nav-link active"
                  : "nav-item nav-link"
              }
            >
              Contact
            </a>
          </div>
          <a
            href="/create-jobs"
            className="btn btn-primary rounded-0 py-4 px-lg-5 d-none d-lg-block"
          >
            Post A Job
            <i className="fa fa-arrow-right ms-3" />
          </a>
        </div>
      </nav>
    </>
  );
};
export default Header;
