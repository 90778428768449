/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { getJobs } from "../services/jobs";

import Carousel from "./pages/Carousel";
import Header from "./pages/header";
import Footer from "./pages/footer";
import carousel_1 from "../img/carousel_1.jpg";
import about_1 from "../img/about_1.jpg";
import about_2 from "../img/about_2.jpg";
import about_3 from "../img/about_3.jpg";
import about_4 from "../img/about_4.jpg";

const Home = () => {
  // const [jobs, setJobs] = useState([]);

  // useEffect(() => {
  //   const fetchJobsData = async () => {
  //     try {
  //       const response = await getJobs();
  //       setJobs(response.data);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };

  //   fetchJobsData();
  // }, []);

  // console.log(jobs, "******* jobs");

  // const [searchTerm, setSearchTerm] = useState('');
  // const [inputs, setInputs] = useState({});

  // const handleSearchChange = (e) => {
  //   e.preventDefault();
  //   setSearchTerm(inputs);
  // };

  // const handleChange = (event) => {
  //   const name = event.target.name;
  //   const value = event.target.value;
  //   setInputs((values) => ({ ...values, [name]: value }));
  // };

  // console.log(inputs, '****47*', searchTerm)

  return (
    <>
      {/* <div id="spinner" className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
          <div className="spinner-border text-primary" style={{width: '3rem', height: '3rem'}} role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div> */}
      <Header path="/"></Header>
      <div className="container-fluid p-0">
        <div className="owl-carousel header-carousel position-relative">
          <div className="owl-carousel-item position-relative">
            <img className="img-fluid" src={carousel_1} alt="" />
            <div
              className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center"
              style={{ background: "rgba(43, 57, 64, .5)" }}
            >
              <div className="container">
                <div className="row justify-content-start">
                  <div className="col-10 col-lg-8">
                    <h1 className="display-3 text-white animated slideInDown mb-4">
                      Find The Perfect Job That You Deserved
                    </h1>
                    <p className="fs-5 fw-medium text-white mb-4 pb-2"></p>
                    <a
                      href="/jobs"
                      className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft"
                    >
                      Search A Job
                    </a>
                    {/* <a
                      href="/"
                      className="btn btn-secondary py-md-3 px-md-5 animated slideInRight"
                    >
                      Find A Talent
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* About Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
              <div className="row g-0 about-bg rounded overflow-hidden">
                <div className="col-6 text-start">
                  <img className="img-fluid w-100" src={about_1} />
                </div>
                <div className="col-6 text-start">
                  <img
                    className="img-fluid"
                    src={about_2}
                    style={{ width: "85%", marginTop: "15%" }}
                  />
                </div>
                <div className="col-6 text-end">
                  <img
                    className="img-fluid"
                    src={about_3}
                    style={{ width: "85%" }}
                  />
                </div>
                <div className="col-6 text-end">
                  <img className="img-fluid w-100" src={about_4} />
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
              <h1 className="mb-4">
                Welcome to Career Connect: Your Gateway to Job Success and
                Talent Exploration
              </h1>
              <p className="mb-4">
                Our portal is dedicated to revolutionizing the job search
                experience for both job seekers and employers. With our
                innovative platform, we specialize in connecting individuals
                with their dream jobs while assisting companies in finding top
                talent. Through personalized job matching algorithms, expert
                career guidance, and interactive networking tools, we empower
                users to navigate their career paths with confidence. Whether
                you're a job seeker seeking the perfect opportunity or an
                employer looking to discover exceptional talent, our portal is
                your ultimate destination for success in the dynamic world of
                recruitment.
              </p>
              <p>
                <i className="fa fa-check text-primary me-3" />
                <b>Personalized Job Matching: </b>
                Utilize advanced algorithms to match job seekers with the most
                suitable positions based on their skills, experience, and
                preferences.
              </p>
              <p>
                <i className="fa fa-check text-primary me-3" />
                <b>Comprehensive Job Listings: </b>
                Offer an extensive database of job opportunities across various
                industries and sectors to cater to diverse career interests.
              </p>
              <p>
                <i className="fa fa-check text-primary me-3" />
                <b>Expert Career Guidance: </b>
                Provide personalized guidance from industry experts to help job
                seekers navigate their career paths and make informed decisions.
                Featured companies actively hiring
              </p>
              <p>
                <i className="fa fa-check text-primary me-3" />
                <b>Continuous Support and Feedback: </b>
                Provide ongoing support to job seekers throughout their job
                search journey, incorporating feedback to enhance user
                experience and outcomes.
              </p>
              {/* <a className="btn btn-primary py-3 px-5 mt-3" href="/">
                Read More
              </a> */}
            </div>
          </div>
        </div>
      </div>
      <Carousel />
      <Footer></Footer>
      <a href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top">
        <i className="bi bi-arrow-up" />
      </a>
    </>
  );
};
export default Home;
