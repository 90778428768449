import "./App.css";
import "./css/bootstrap.min.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AboutUs from "./components/pages/about_us";
import Contact from "./components/pages/contact";
import JobDetails from "./components/pages/job_details";
import JobList from "./components/pages/job_list";
import Category from "./components/pages/category";
import CreateJob from "./components/jobs/create_job";
import Login from "./components/registration/login"

import Home from "./components/home";

function App() {
  return (
    <div className="container-xxl bg-white p-0">
      <link
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.10.0/css/all.min.css"
        rel="stylesheet"
      />
      <link
        href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.4.1/font/bootstrap-icons.css"
        rel="stylesheet"
      />
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/job-details/:id" element={<JobDetails />} />
          <Route path="/jobs" element={<JobList />} />
          <Route path="/create-jobs" element={<Login />} />
          <Route path="/category" element={<Category />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
