const BASE_URL = "https://jobentry.backend.maltimediagroup.in";

export async function getJobs() {
  const response = await fetch(BASE_URL + `/api/getJobs`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response;
}

export async function getJob(job_id) {
  const response = await fetch(BASE_URL + `/api/jobs/${job_id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  return response;
}

export async function createJobs(body) {
  const response = await fetch(BASE_URL + "/api/jobs", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  });
  return response
}

export async function searchJobs(data) {
  const response = await fetch(BASE_URL + "/api/searchJobs", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  return response;
}

export async function createLogin(data) {
  const response = await fetch("/api/createLogin", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  return response
}
