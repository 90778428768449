/* eslint-disable react/jsx-no-target-blank */
const Footer = () => {
  const appName = document.location.origin.includes("maltimediagroup")
    ? "Maltimedia Group"
    : "Ryientsoft Technologies";
  const baseUrl = document.location.origin.includes("maltimediagroup")
    ? "http://www.maltimediagroup.com"
    : "https://www.ryientsoft.com";
  const emailAddress = document.location.origin.includes("maltimediagroup")
    ? "hr@maltimediagroup.com"
    : "info@ryientsoft.com";

  const handleSignup = async (event) => {
    event.preventDefault();
    alert("Thank you for Newsletter");
  };

  return (
    <>
      <div
        className="container-fluid bg-dark text-white-50 footer pt-5 mt-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-3 col-md-6">
              <h5 className="text-white mb-4">Company</h5>
              <a className="btn btn-link text-white-50" href="/about">
                About Us
              </a>
              <a className="btn btn-link text-white-50" href="/contact">
                Contact Us
              </a>
              {/* <a className="btn btn-link text-white-50" href>
                Our Services
              </a>
              <a className="btn btn-link text-white-50" href>
                Privacy Policy
              </a>
              <a className="btn btn-link text-white-50" href>
                Terms &amp; Condition
              </a> */}
            </div>
            {/* <div className="col-lg-3 col-md-6">
              <h5 className="text-white mb-4">Quick Links</h5>
              <a className="btn btn-link text-white-50" href>
                About Us
              </a>
              <a className="btn btn-link text-white-50" href>
                Contact Us
              </a>
              <a className="btn btn-link text-white-50" href>
                Our Services
              </a>
              <a className="btn btn-link text-white-50" href>
                Privacy Policy
              </a>
              <a className="btn btn-link text-white-50" href>
                Terms &amp; Condition
              </a>
            </div> */}
            <div className="col-lg-3 col-md-6">
              <h5 className="text-white mb-4">Contact</h5>
              <p className="mb-2">
                <i className="fa fa-map-marker-alt me-3" />
                H-96 Sector 63, Noida, UP
              </p>
              <p className="mb-2">
                <i className="fa fa-phone-alt me-3" />
                {emailAddress}
              </p>
              <p className="mb-2">
                <i className="fa fa-envelope me-3" />
                {emailAddress}
              </p>
              <div className="d-flex pt-2">
                <a className="btn btn-outline-light btn-social" href>
                  <i className="fab fa-twitter" />
                </a>
                <a className="btn btn-outline-light btn-social" href>
                  <i className="fab fa-facebook-f" />
                </a>
                <a className="btn btn-outline-light btn-social" href>
                  <i className="fab fa-youtube" />
                </a>
                <a className="btn btn-outline-light btn-social" href>
                  <i className="fab fa-linkedin-in" />
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <h5 className="text-white mb-4">Other Products</h5>
              <p>
                <a href="https://www.savingonmore.com" target="_blank">
                  S.O.M. PRODUCTS
                </a>
              </p>
              {/* <div
                className="position-relative mx-auto"
                style={{ maxWidth: "400px" }}
              >
                <input
                  className="form-control bg-transparent w-100 py-3 ps-4 pe-5"
                  type="email"
                  placeholder="Your email"
                />
                
                <button
                  type="button"
                  className="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2"
                  onClick={handleSignup}
                >
                  SignUp
                </button>
              </div> */}
            </div>
          </div>
        </div>
        <div className="container">
          <div className="copyright">
            <div className="row">
              <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                ©{" "}
                {/* <a className="border-bottom" href="#">
                  Jobs
                </a> */}
                All Right Reserved.
                {/*/*** This Job Portal Website is free as long as you keep the footer author’s credit link/attribution link/backlink. If you'd like to use the Job Portal Website without the footer author’s credit link/attribution link/backlink, you can purchase the Credit Removal License from "https://htmlcodex.com/credit-removal". Thank you for your support. *** /*/}
                Designed By{" "}
                <a
                  className="border-bottom"
                  target="_blank"
                  href={baseUrl}
                  rel="noreferrer"
                >
                  {appName}
                </a>
              </div>
              <div className="col-md-6 text-center text-md-end">
                <div className="footer-menu">
                  <a href="/">Home</a>
                  <a href="/about">About Us</a>
                  <a href="/jobs">Jobs</a>
                  <a href="/contact">Contact</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
