/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import Header from "./header";
import Footer from "./footer";

const Contact = () => {
  const [inputs, setInputs] = useState({});

  const emailAddress = document.location.origin.includes("maltimediagroup")
    ? "hr@maltimediagroup.com"
    : "info@ryientsoft.com";

    const handleSubmit = async (event) => {
      event.preventDefault();
      alert('Thank you for Contact Us')
    };

    const handleChange = (event) => {
      const name = event.target.name;
      const value = event.target.value;
      setInputs((values) => ({ ...values, [name]: value }));
    };

  return (
    <>
      <div>
        <div className="container-xxl bg-white p-0">
          {/* Navbar Start */}
          <Header path="contact"></Header>
          {/* Navbar End */}
          {/* Header End */}
          <div className="container-xxl py-5 bg-dark page-header mb-5">
            <div className="container my-5 pt-5 pb-4">
              <h1 className="display-3 text-white mb-3 animated slideInDown">
                Contact
              </h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb text-uppercase">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  {/* <li className="breadcrumb-item">
                    <a href="#">Pages</a>
                  </li> */}
                  <li
                    className="breadcrumb-item text-white active"
                    aria-current="page"
                  >
                    Contact
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          {/* Header End */}
          {/* Contact Start */}
          <div className="container-xxl py-5">
            <div className="container">
              <h1
                className="text-center mb-5 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                Contact For Any Query
              </h1>
              <div className="row g-4">
                <div className="col-12">
                  <div className="row gy-4">
                    <div className="col-md-4 wow fadeIn" data-wow-delay="0.1s">
                      <div className="d-flex align-items-center bg-light rounded p-4">
                        <div
                          className="bg-white border rounded d-flex flex-shrink-0 align-items-center justify-content-center me-3"
                          style={{ width: "45px", height: "45px" }}
                        >
                          <i className="fa fa-map-marker-alt text-primary" />
                        </div>
                        <span>H-96 Sector 63, Noida, UP</span>
                      </div>
                    </div>
                    <div className="col-md-4 wow fadeIn" data-wow-delay="0.3s">
                      <div className="d-flex align-items-center bg-light rounded p-4">
                        <div
                          className="bg-white border rounded d-flex flex-shrink-0 align-items-center justify-content-center me-3"
                          style={{ width: "45px", height: "45px" }}
                        >
                          <i className="fa fa-envelope-open text-primary" />
                        </div>
                        <span>{emailAddress}</span>
                      </div>
                    </div>
                    <div className="col-md-4 wow fadeIn" data-wow-delay="0.5s">
                      <div className="d-flex align-items-center bg-light rounded p-4">
                        <div
                          className="bg-white border rounded d-flex flex-shrink-0 align-items-center justify-content-center me-3"
                          style={{ width: "45px", height: "45px" }}
                        >
                          <i className="fa fa-phone-alt text-primary" />
                        </div>
                        <span>{emailAddress}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                  <iframe
                    className="position-relative rounded w-100 h-100"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3501.9636649290064!2d77.37954937604478!3d28.63085107566566!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ceff90f1c0a5d%3A0x42dc123b16dd655b!2s96%2C%20Sector%2063%20Rd%2C%20D%20Block%2C%20Sector%2062%2C%20Noida%2C%20Uttar%20Pradesh%20201307!5e0!3m2!1sen!2sin!4v1707071735819!5m2!1sen!2sin"
                    frameBorder={0}
                    style={{ minHeight: "400px", border: 0 }}
                    allowFullScreen
                    aria-hidden="false"
                    tabIndex={0}
                  />
                </div>
                <div className="col-md-6">
                  <div className="wow fadeInUp" data-wow-delay="0.5s">
                    <p className="mb-4">
                      If you have any questions or require further information
                      about the position, please feel free to contact us at:
                    </p>
                    <form onSubmit={handleSubmit}>
                      <div className="row g-3">
                        <div className="col-md-6">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              placeholder="Your Name"
                              name="name"
                              value={inputs.name || ""}
                              onChange={handleChange}
                            />
                            <label htmlFor="name">Your Name</label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-floating">
                            <input
                              type="email"
                              className="form-control"
                              id="email"
                              placeholder="Your Email"
                              name="email"
                              value={inputs.email || ""}
                              onChange={handleChange}
                            />
                            <label htmlFor="email">Your Email</label>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control"
                              id="subject"
                              placeholder="Subject"
                              name="subject"
                              value={inputs.subject || ""}
                              onChange={handleChange}
                            />
                            <label htmlFor="subject">Subject</label>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-floating">
                            <textarea
                              className="form-control"
                              placeholder="Leave a message here"
                              id="message"
                              style={{ height: "150px" }}
                              name="description"
                              value={inputs.description || ""}
                              onChange={handleChange}
                            />
                            <label htmlFor="message">Message</label>
                          </div>
                        </div>
                        <div className="col-12">
                          <button
                            className="btn btn-primary w-100 py-3"
                            type="submit"
                          >
                            Send Message
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Contact End */}
          {/* Footer Start */}
          <Footer></Footer>
          {/* Footer End */}
          {/* Back to Top */}
          <a
            href="#"
            className="btn btn-lg btn-primary btn-lg-square back-to-top"
          >
            <i className="bi bi-arrow-up" />
          </a>
        </div>
        {/* JavaScript Libraries */}
        {/* Job Portal Website Javascript */}
      </div>
    </>
  );
};
export default Contact;
