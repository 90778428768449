/* eslint-disable jsx-a11y/anchor-is-valid */
import Header from "../pages/header";
import Footer from "../pages/footer";
import React, { useState } from "react";
import { createLogin } from "../../services/jobs";

const Login = () => {
  const [inputs, setInputs] = useState({});
  // const [createdJob, setCreatedJob] = useState({});
  const [getError, setErrors] = useState({});

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // const resposne = await createLogin(inputs);
    // if (resposne.status) {
    //   if (resposne.data.message) {
    //     setErrors(resposne.data);
    //   } else {
    //     setErrors({ message: "Job Created Successfully!" });
    //     setInputs({});
    //   }
    // } else {
    setErrors({ message: "Something Went Wrong!" });
    // }
  };

  return (
    <div>
      <div className="container-xxl bg-white p-0">
        <Header path="job_details"></Header>
        {/* Header End */}
        {/* Job Detail Start */}
        <div className="container-xxl py-5 wow fadeInUp" data-wow-delay="0.1s">
          <div className="container">
            <div className="row gy-5 gx-4">
              <div className="col-lg-8">
                <a className="breadcrumb-item">{getError.message}</a>
                <div className>
                  <h4 className="mb-4">Corporate Admin Login</h4>
                  <form onSubmit={handleSubmit}>
                    <div className="row g-3">
                      <div className="col-12 col-sm-6">
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Email"
                          name="email"
                          value={inputs.email || ""}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-12 col-sm-6">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="password"
                          name="password"
                          value={inputs.password || ""}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="col-12">
                        <button className="btn btn-primary w-100" type="submit">
                          Log in
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="bg-light rounded p-5 mb-4 wow slideInUp"
                  data-wow-delay="0.1s"
                >
                  <h4 className="mb-4">Employees Login</h4>
                  <div className="col-12">
                    <button className="btn btn-primary w-100" type="submit">
                      Log in
                    </button>
                  </div>
                  {/* <p>
                    <i className="fa fa-angle-right text-primary me-2" />
                    Published On: 01 Jan, 2045
                  </p>
                  <p>
                    <i className="fa fa-angle-right text-primary me-2" />
                    Vacancy: 123 Position
                  </p>
                  <p>
                    <i className="fa fa-angle-right text-primary me-2" />
                    Job Nature: Full Time
                  </p>
                  <p>
                    <i className="fa fa-angle-right text-primary me-2" />
                    Salary: $123 - $456
                  </p>
                  <p>
                    <i className="fa fa-angle-right text-primary me-2" />
                    Location: New York, USA
                  </p>
                  <p className="m-0">
                    <i className="fa fa-angle-right text-primary me-2" />
                    Date Line: 01 Jan, 2045
                  </p> */}
                </div>
                {/* <div
                  className="bg-light rounded p-5 wow slideInUp"
                  data-wow-delay="0.1s"
                >
                  <h4 className="mb-4">Company Detail</h4>
                  <p className="m-0">
                    Our Company is a leading IT industry/sectors.
                  </p>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        {/* Job Detail End */}
        {/* Footer Start */}
        <Footer></Footer>
        {/* Footer End */}
        {/* Back to Top */}
        <a
          href="#"
          className="btn btn-lg btn-primary btn-lg-square back-to-top"
        >
          <i className="bi bi-arrow-up" />
        </a>
      </div>
      {/* JavaScript Libraries */}
      {/* Job Portal Website Javascript */}
    </div>
  );
};

export default Login;
